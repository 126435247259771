<template>
  <el-carousel height="180px">
    <el-carousel-item :key="index" v-for="(item, index) in dataList">
      <!-- <el-image :src="item.img" fit="contain"></el-image> -->
      <img :src="item.img" style="width: 100%; height: 180px;" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  data() {
    return {
      dataList: []
    }
  },
  mounted() {
    this.$axios.post('/banner/lists').then(res => {
      if (res.data.code == 200) {
        this.dataList = res.data.data.list
      }
    })
  }
}
</script>