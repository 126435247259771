<template>
  <div data-title="实时动态" v-title>
    <el-card class="main-content">
      <div class="clearfix" slot="header">
        <i class="el-icon-s-promotion" style="font-size: 18px"></i> 快捷方式
      </div>
      <div class="jue-card-body line1">
        <router-link :to="{name:'customer-register'}" class="link" style="background: #74d23a;">
          <div class="link-icon">
            <i class="el-icon-jue-supervisor_account-px"></i>
          </div>
          <span class="link-text">意向客户登记</span>
        </router-link>
        <router-link :to="{name:'customer-data'}" class="link" style="background: #fe7b43;">
          <div class="link-icon">
            <i class="el-icon-document"></i>
          </div>
          <span class="link-text">意向客户档案</span>
        </router-link>
        <router-link :to="{name:'customer-trace'}" class="link" style="background: #feab3e;">
          <div class="link-icon">
            <i class="el-icon-jue-phone_in_talk-px"></i>
          </div>
          <span class="link-text">意向客户跟踪</span>
        </router-link>
        <router-link :to="{name:'company-task'}" class="link" style="background: #587df3;">
          <div class="link-icon">
            <i class="el-icon-jue-playlist_add_check-px"></i>
          </div>
          <span class="link-text">任务查看审核</span>
        </router-link>
      </div>
      <div class="jue-card-body">
        <router-link :to="{name:'chart-sell'}" class="link" style="background: #40a9fe;">
          <div class="link-icon">
            <i class="el-icon-jue-tubiaozhuzhuangtu1"></i>
          </div>
          <span class="link-text">销售数据统计</span>
        </router-link>
        <router-link :to="{name:'chart-sellone'}" class="link" style="background: #33d1cb;">
          <div class="link-icon">
            <i class="el-icon-jue-ziyoubiao"></i>
          </div>
          <span class="link-text">销售分析</span>
        </router-link>
        <router-link :to="{name:'chart-cmarket'}" class="link" style="background: #9250df;">
          <div class="link-icon">
            <i class="el-icon-jue-liandongtu"></i>
          </div>
          <span class="link-text">客户数据分析</span>
        </router-link>
        <router-link :to="{name:'chart-selltwo'}" class="link" style="background: #e91e63;">
          <div class="link-icon">
            <i class="el-icon-jue-tubiaoduoweizhuzhuangtu"></i>
          </div>
          <span class="link-text">销售顾问分析</span>
        </router-link>
      </div>
    </el-card>

    <div class="main">
      <el-row :gutter="20">
        <el-col :span="16">
          <div class="body">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-card class="box-card">
                  <div class="clearfix" slot="header">
                    <i class="el-icon-star-off" style="font-size: 18px"></i> 任务概述
                  </div>
                  <div class="body">
                    <el-form label-position="left" label-width="50%">
                      <el-form-item label="当日应回访数">
                        <span class="blue">{{indexData.today_return_count}}</span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="当日剩余回访数">
                        <span
                          @click="$router.push({name: 'customer-trace', query: { type: 'today' } })"
                          class="blue linkto"
                        >
                          <i class="el-icon-link"></i>
                          {{indexData.today_un_return_visit}}
                        </span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="当日跟踪次数">
                        <span class="blue">{{indexData.today_return_visit}}</span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="当月订单目标">
                        <span class="blue">{{indexData.month_order_target}}</span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="当月已检核数量">
                        <span class="blue">{{indexData.checked}}</span>
                        <span class="unit">个</span>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <div class="clearfix" slot="header">
                    <i class="el-icon-star-off" style="font-size: 18px"></i> 实时数据
                  </div>
                  <div class="body">
                    <el-form label-position="left" label-width="50%">
                      <el-form-item label="继续跟踪客户数">
                        <span
                          @click="$router.push({name: 'customer-trace', query: { type: 'list' } })"
                          class="blue linkto"
                        >
                          <i class="el-icon-link"></i>
                          {{indexData.trace_process}}
                        </span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="重点客户数">
                        <span
                          @click="$router.push({name: 'customer-trace', query: { type: 'vip' } })"
                          class="blue linkto"
                        >
                          <i class="el-icon-link"></i>
                          {{indexData.vip}}
                        </span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="未交车订单">
                        <span class="blue">{{indexData.month_order_finish}}</span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="当月交车数">
                        <span
                          @click="$router.push({name: 'customer-trace', query: { type: 'succeed' } })"
                          class="blue linkto"
                        >
                          <i class="el-icon-link"></i>
                          {{indexData.month_delivery_finish}}
                        </span>
                        <span class="unit">个</span>
                      </el-form-item>
                      <el-form-item label="当月战败客户数">
                        <span
                          @click="$router.push({name: 'customer-trace', query: { type: 'failed' } })"
                          class="blue linkto"
                        >
                          <i class="el-icon-link"></i>
                          {{indexData.month_fall}}
                        </span>
                        <span class="unit">个</span>
                      </el-form-item>
                      
                    </el-form>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <el-card class="box-card" style="height: 315px">
            <div class="clearfix" slot="header">
              <i class="el-icon-jue-cast-px" style="font-size: 18px"></i> 公告列表
            </div>
            <div class="table-wrapper">
              <el-table
                :data="newsData"
                :style="'width: '+$store.state.tableWidth"
                @row-click="toNewsDetail"
                class="table"
                max-height="250"
                size="medium"
                style="width: 100%"
              >
                <el-table-column label="标题" prop="title"></el-table-column>
                <el-table-column label="发布日期" prop="created_at"></el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import NewsSilde from '@/components/dashboard/NewsSilde.vue'
export default {
  components: {
    // NewsSilde
  },
  data() {
    return {
      newsData: [],
      indexData: {}
    }
  },
  mounted() {
    this.getIndexData()
    this.getNewsData()
  },
  methods: {
    getIndexData() {
      this.$axios.post('/index/index').then(res => {
        this.indexData = res.data.data
      })
    },
    getNewsData() {
      this.$axios.post('/news/lists', this.key).then(res => {
        if (res.data.code == 200) {
          this.newsData = res.data.data.list
        }
      })
    },
    toNewsDetail(row) {
      console.log(row)
      this.$router.push({
        name: 'news-view',
        query: { news_id: row.news_id }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  padding: 20px;
  /deep/ .el-row {
    margin-bottom: 20px;
  }
}
.blue {
  color: #ff9800;

  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  text-align: right;
  &.linkto {
    cursor: pointer;
  }
}
.unit {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding: 0 10px;
}
.table-wrapper {
  margin: -20px -20px 0;
}
.jue-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  // justify-content: center;
  &.line1 {
    margin-bottom: 20px;
  }
}
.link {
  display: block;
  text-align: center;
  // border: 1px solid #dcdfe6;
  width: 150px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  // margin-right: 100px;
  // margin-bottom: 20px;
  color: #fff;
  &:hover {
    background: rgba(217, 236, 255, 0.45);
    // border-color: rgba(217, 236, 255, 0.45);
  }
  i {
    font-size: 40px;
  }
  .link-text {
    margin-top: 10px;
    font-weight: bold;
  }
}
/deep/ .el-form {
  width: 100%;
  .el-form-item--small.el-form-item {
    margin-bottom: 8px;
  }

  .el-form-item__content {
    text-align: right;
  }
}
.el-icon-link {
  font-size: 10px;
  color: #1890ff;
}
</style>